import React from 'react';
import { Link } from 'gatsby';

// Layout Imports
import Default from '../layouts/Default/Default';
import SEO from '../layouts/SEO/SEO';

// Component Imports
import PageHeader from '../components/shared/PageHeader/PageHeader';

const NotFoundPage = (props) => {
    return (
      <Default
        location={props.location}
        title='Page not found'
      >
        <SEO title='Page not found' />

        <PageHeader
          heading='Page not found'
          description={<>Awkward... that page doesn't exist. Please return to the <Link to='/'>homepage</Link> and try again!</>}
          noChevron
        />
      </Default>
    );
};

export default NotFoundPage;